import React from 'react';
import { Link } from 'gatsby';
import './PrimaryButton.scss';
import classNames from 'classnames';

const PrimaryButton = ({ label, onClick, redirectTo, ...props }) => {
  const buttonClass = classNames({
    'custom-button': true,
    link: redirectTo,
    purple: props?.purple,
    white: props?.white,
    gray: props?.gray,
    small: props?.small,
    big: props?.big,
    large: props?.large,
    inactive: props?.inactive,
    pressed: props?.pressed,
  });

  return (
    <>
      {redirectTo ? (
        <Link to={redirectTo} className={buttonClass} onClick={onClick} state={props?.state}>
          {props?.isLoading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          ) : (
            <span className="title extrabold">{label}</span>
          )}
        </Link>
      ) : (
        <button
          type={props?.type}
          className={buttonClass}
          onClick={onClick}
          disabled={props?.inactive || props?.isLoading}
        >
          {props?.isLoading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          ) : (
            <span className="title extrabold">{label}</span>
          )}
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
