import React from 'react';
import { Link } from 'gatsby';

import './WrapperFooter.scss';

const WrapperFooter = () => {
  return (
    <div className="wrapper-footer-container container">
      <span className="title head8">© Contacts Portal</span>
      {/* <div className="footer-navbar">
        <Link to="/contact">
          <span className="title head8">Contact</span>
        </Link>
        <Link to="#">
          <span className="title head8">Help</span>
        </Link>
      </div> */}
    </div>
  );
};

export default WrapperFooter;
