import React from 'react';
import { logout, isLoggedIn, getCurrentUser } from '../../../services/auth';
import { navigate, Link } from 'gatsby';

import './WrapperLogo.scss';
import backArrow from '../../mainPage/img/back-arrow.png';

const WrapperLogo = ({ label, active, onClick }) => {
  const handleClick = () => {
    onClick ? onClick() : logout(() => navigate(`/`));
  };

  return (
    <div className="wrapper-logo-container container">
      <Link to={isLoggedIn() ? `/${getCurrentUser()?.type}/account` : '/'} className="wrapper-logo">
        <span className="title extrabold">Contacts</span>
        <span className="title purple">Portal</span>
      </Link>
      {active && (
        <div className="nav-container">
          <button onClick={handleClick}>
            <div className="wrapper">
              <div>
                <img alt="" src={backArrow} />
              </div>
              <span className="title bold">{label || 'Logout'}</span>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default WrapperLogo;
