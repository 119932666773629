import API from './api';

export const getDoctorCustomerAddress = (customer_id) => {
  return API.get('doctors/customer_addresses', {
    params: {
      customer_id,
    },
  });
};

export const getCustomerAddress = () => {
  return API.get('customers/shipping_addresses');
};

export const getOrderHistory = (typeOfUser, page, sort, customer_id, order_id) => {
  return API.get(`${typeOfUser}s/order_history`, {
    params: { page, sort_by_value: sort, customer_id, order_id },
  });
};

export const getOrder = (order_id) => {
  return API.get(`customers/orders/${order_id}`);
};

export const getCustomers = () => {
  return API.get('doctors/customers');
};

export const getPayouts = () => {
  return API.get('doctors/payouts');
};

export const getSubscriptions = (typeOfUser) => {
  return API.get(`${typeOfUser}s/subscriptions`);
};

export const getPatientSubscriptions = (typeOfUser, patientId) => {
  return API.get(`${typeOfUser}s/${patientId}/subscriptions`);
};

export const getDoctorPaymentInfo = (doctorId) => {
  return API.get(`doctors/${doctorId}/get_bank_info`);
};

export const toggleSubscription = (typeOfUser, subscriptionId) => {
  return API.post(`${typeOfUser}s/subscriptions/${subscriptionId}/toggle`);
};

export const skipSubscription = (typeOfUser, subscriptionId) => {
  return API.post(`${typeOfUser}s/subscriptions/${subscriptionId}/skip_subscription`);
};

export const orderNow = (typeOfUser, subscriptionId) => {
  return API.post(`${typeOfUser}s/subscriptions/${subscriptionId}/order_now`);
};

export const updatePersonalInfoPUT = (typeOfUser, first_name, last_name, phone) => {
  return API.put(`${typeOfUser}s`, {
    [typeOfUser]: {
      first_name,
      last_name,
      phone,
    },
  });
};

export const updateDoctorCustomerPUT = (customer_id, first_name, last_name, phone) => {
  return API.put(`doctors/customers/${customer_id}`, {
    customer: {
      first_name,
      last_name,
      phone,
    },
  });
};

export const updateCustomerAddress = (address, typeOfUser) => {
  const { id, address1, address2, city, province, zip, customer_id } = address;
  const shippingUrl =
    typeOfUser === 'customer'
      ? `customers/shipping_addresses/${id}`
      : `doctors/customer_addresses/${id}`;

  return API.put(shippingUrl, {
    shipping_address: {
      address1,
      address2,
      city,
      province,
      zip,
    },
    customer_id,
  });
};

export const createCustomerAddress = (address) => {
  return API.post('shipping_addresses', { shipping_address: address });
};

export const resetPassword = (typeOfUser, email) => {
  return API.get(`${typeOfUser}s/passwords`, { params: { email } });
};

export const createPasswordPOST = (
  typeOfUser,
  reset_password_token,
  password,
  password_confirmation,
) => {
  return API.post(`${typeOfUser}s/passwords`, {
    [typeOfUser]: { reset_password_token, password, password_confirmation },
  });
};

export const updatePasswordPOST = (typeOfUser, password, password_confirmation) => {
  return API.post(`${typeOfUser}s/passwords`, {
    [typeOfUser]: { password, password_confirmation },
  });
};

export const createOrderPOST = (order) => {
  return API.post('orders', order);
};

export const logoutPOST = (typeOfUser) => {
  return API.delete(`${typeOfUser}s/sign_out`);
};
