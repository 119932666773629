import axios from 'axios';

const isBrowser = typeof window !== `undefined`;

const getToken = () => {
  if (!isBrowser) return false;

  return JSON.parse(localStorage.getItem('gatsbyUser'))?.token;
};

const API = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}/api/v1/`,
  headers: { Authorization: getToken() },
});

API.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = getToken();
  } else {
    config.headers = {};
  }

  return config;
});

export default API;
