import axios from 'axios';

import { logoutPOST } from './requests';

const isBrowser = typeof window !== `undefined`;

export const getUser = () => JSON.parse(localStorage.getItem('gatsbyUser')) || {};

export const setUser = (user) => localStorage.setItem('gatsbyUser', JSON.stringify(user));

export const handleLogin = async ({ email, password, typeOfUser }) => {
  if (!isBrowser) return false;

  const attributeName = typeOfUser == 'doctor' ? 'doctor' : 'customer';

  const loginCreds = { [attributeName]: { email: email, password: password } };
  const singInUrl = `${process.env.GATSBY_API_URL}/api/v1/${attributeName}s/sign_in`;

  const loginResponse = await axios.post(singInUrl, loginCreds);
  // const loginResponse = await fetchWithAuth(singInUrl, loginCreds);

  if (loginResponse) {
    const token = loginResponse?.headers?.authorization;
    const userDataUrl = `${process.env.GATSBY_API_URL}/api/v1/${attributeName}s`;
    const userRes = await axios.get(userDataUrl, { headers: { Authorization: token } });
    if (userRes.data) {
      return setUser({
        id: userRes.data.id,
        email: userRes.data.email,
        type: attributeName,
        first_name: userRes.data.first_name,
        last_name: userRes.data.last_name,
        token: loginResponse?.headers?.authorization,
        stripe_account_id: userRes.data.stripe_account_id,
        kyc_requirements: userRes.data.kyc_requirements,
        phone: userRes.data.phone,
        payout_setup: userRes.data.payout_setup,
      });
    }
    localStorage.setItem('auth-token', loginResponse.headers.authorization);
  }

  return false;
};

export const handleRegister = async ({
  first_name,
  last_name,
  phone,
  email,
  password,
  typeOfUser,
  doctor_id,
}) => {
  if (!isBrowser) return false;

  const attributeName = typeOfUser == 'doctor' ? 'doctor' : 'customer';

  const signUpUrl = `${process.env.GATSBY_API_URL}/api/v1/${attributeName}s/sign_up`;
  const signUpCreds =
    typeOfUser == 'doctor'
      ? {
          [attributeName]: { first_name, last_name, email, password },
        }
      : {
          [attributeName]: { first_name, last_name, phone, email, password, doctor_id },
        };

  if (typeOfUser === 'customer') {
    const token = getUser().token;
    return await axios.post(signUpUrl, signUpCreds, { headers: { Authorization: token } });
  }
  return await axios.post(signUpUrl, signUpCreds);
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();
  return !!user.email;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback) => {
  if (!isBrowser) return;

  const user = getUser();

  if (!user?.type) return;

  console.log(`Ensuring the \`gatsbyUser\` property exists.`);

  logoutPOST(user.type)
    .then((response) => {
      setUser({});
      callback?.();
    })
    .catch((e) => console.log(e));
};

const saveToken = (token) => {
  localStorage.setItem('auth-token', token);
};

const refreshToken = async (token) => {
  const refreshTokenUrl = `${process.env.GATSBY_API_URL}/api/v1/refreshToken`;
  const res = await axios.post(refreshTokenUrl, { headers: { Authorization: token } });

  if (res) {
    const tokenData = res.json();
    saveToken(JSON.stringify(tokenData));
  }
};

const fetchWithAuth = async (loginUrl, loginCreds) => {
  let tokenData = null;

  if (localStorage.getItem('auth-token')) {
    tokenData = JSON.parse(localStorage.getItem('auth-token'));
  } else {
    return window.location.replace(loginUrl);
  }

  if (tokenData) {
    if (Date.now() >= tokenData.expires_on * 1000) {
      try {
        const newToken = await refreshToken(tokenData.refresh_token);
        saveToken(newToken);
      } catch {
        return window.location.replace(loginUrl);
      }
    }

    loginCreds.headers.Authorization = `Bearer ${tokenData.token}`;
  }

  return fetch(loginUrl, loginCreds);
};
